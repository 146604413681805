/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";

import NETWORK_DATA from "../data/network";
import { Button, Input } from "deventds2";

function EditNetworkJson() {
  const [updatedNodes, setUpdatedNodes] = useState(NETWORK_DATA.nodes);

  const updateItem = (
    id: string,
    key: "name" | "group" | "year",
    item: string | number
  ) => {
    const index = updatedNodes.findIndex((el) => {
      return el.id == id;
    });

    if (key == "year") {
      updatedNodes[index][key] = Number(item);
    } else if (key == "name" || key == "group") {
      updatedNodes[index][key] = String(item);
    }

    setUpdatedNodes([...updatedNodes]);
  };

  const removeItem = (id: string) => {
    const index = updatedNodes.findIndex((el) => {
      return el.id == id;
    });

    updatedNodes.splice(index, 1);
    setUpdatedNodes([...updatedNodes]);
  };

  const addNewItem = () => {
    updatedNodes.push({
      name: "",
      year: 1,
      id: String(updatedNodes.length + 1),
      value: 3,
      group: "과학자",
    });

    setUpdatedNodes([...updatedNodes]);
  };

  return (
    <div css={css({ height: "60vh", overflow: "scroll" })}>
      <div
        css={css({
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
        })}
      >
        {updatedNodes.map((el) => (
          <div
            css={css({
              display: "flex",
              flexDirection: "row",
              gap: "0.4rem",
            })}
          >
            <Input
              onChange={(e: any) => updateItem(el.id, "name", e.target.value)}
              placeholder="이름"
              value={el.name}
            />

            <Input
              onChange={(e: any) => updateItem(el.id, "year", e.target.value)}
              placeholder="출생년도"
              value={el.year}
              type="number"
            />
            <Input
              onChange={(e: any) => updateItem(el.id, "group", e.target.value)}
              placeholder="그룹"
              value={el.group}
            />
            <Button color="red" onClick={() => removeItem(el.id)}>
              삭제
            </Button>
          </div>
        ))}
      </div>

      <br />

      <Button onClick={addNewItem}>새 지성인 추가</Button>
      {JSON.stringify(updatedNodes)}
    </div>
  );
}

export { EditNetworkJson };
