/** @jsxImportSource @emotion/react */

import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import "./App.css";

import { Network } from "./components/Network";
import { FullScreen } from "./components/FullScreen";


function App() {
  useEffect(() => {
    document.title = "세기의 지성인";
  }, []);
  return (
    <FullScreen>
      <head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />

        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"
        />
      </head>

      <Network></Network>
    </FullScreen>
  );
}


export default App;
