import { Toggle, useColorMode } from "deventds2";

function DarkmodeToggle() {
    const [colorMode, setColorMode] = useColorMode();

    return (
        <Toggle
            checked={colorMode == "light" ? false : true}
            onClick={() => setColorMode(colorMode == "light" ? "dark" : "light")}
        >
            다크모드 on/off
        </Toggle>
    );
}

export { DarkmodeToggle }