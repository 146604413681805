import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, useColorMode } from "deventds2";
import { Global } from '@emotion/react'

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function ThemeGlobalStyle() {
  const [colorMode, setColorMode] = useColorMode();
  return (
    <Global styles={{
      'text': {
        fill: colorMode == "light" ? "#000000" : "#ffffff"
      }
    }} />
  )
}

function Theme({ children }: any) {

  return (
    <ThemeProvider>
      <ThemeGlobalStyle />
      {children}
    </ThemeProvider>
  )
}

root.render(
  <Theme>
    <App />
  </Theme>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
