/** @jsxImportSource @emotion/react */

import React, { useEffect, useRef, useState } from "react";

import * as d3 from "d3";
import NETWORK_DATA from "../data/network";

import { css } from "@emotion/react";
import { SearchInput } from "./Search";
import { Button, Modal, Toggle, useColorMode } from "deventds2";
import { EditNetworkJson } from "./EditNetworkJson";
import { DarkmodeToggle } from "./Darkmode";
import { ButtonSquare } from "./Button";

function Network() {
  const svgRef: any = useRef();
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenCredit, setIsOpenCredit] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchRecommend, setSearchRecommend] = useState([""]);
  const [isDebug, setIsDebug] = useState(false);
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);

  const zoomed = (e: any): void => {
    d3.select(svgRef.current).select("g").attr("transform", e.transform);
  };
  const zoom = d3.zoom().on("zoom", zoomed);

  const [modalData, setModalData] = useState({
    title: "",
    data: {
      name: "",
      year: 1,
      id: "0",
      value: 3,
      group: "과학자",
    },
    links: [
      {
        targetName: "",
        relation: "",
        anecdote: "",
      },
    ],
  });

  const drag = (simulation: any) => {
    function dragstarted(event: any, d: any) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event: any, d: any) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event: any, d: any) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    const returnFunc: any = d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);

    return returnFunc;
  };

  function linkArc(d: any) {
    const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);
    return `
      M${d.source.x},${d.source.y}
      A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
    `;
  }

  const handleClickItem = (e: any) => {
    setIsModalOpen(true);

    setModalDataByName(e.target.innerHTML);
  };

  const setModalDataByName = (fullTitle: string) => {
    const title = fullTitle.split(",")[0];

    const data: any = NETWORK_DATA.nodes.find((item) => {
      return item.name == title;
    });

    const linkData: any = NETWORK_DATA.links
      .filter((item) => {
        return item.source == data.id;
      })
      .map((el) => {
        return {
          targetName: NETWORK_DATA.nodes.find((targeItem) => {
            return targeItem.id == el.target;
          })?.name,
          relation: el.relation,
          anecdote: el.anecdote,
        };
      });

    setModalData({
      title: title,
      data: {
        name: data.name,
        year: data.year,
        id: data.id,
        value: data.value,
        group: data.group,
      },
      links: linkData,
    });
  };

  const handleZoom = () => {
    zoom.scaleBy(d3.select(svgRef.current).transition().duration(750), 1.2);
  };

  const handleZoomOut = () => {
    zoom.scaleBy(d3.select(svgRef.current).transition().duration(750), 0.8);
  };

  const handleClickCredit = () => {
    setIsOpenCredit(true);
  };

  const drawChart = () => {
    console.log("DRAW");

    const links = NETWORK_DATA.links.map(function (d) {
      return Object.create(d);
    });
    const nodes = NETWORK_DATA.nodes.map(function (d) {
      return Object.create(d);
    });

    const fillCircle = function (g: any) {
      return "#ffffff";
    };

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3.forceLink(links).id(function (d: any) {
          return d.id;
        })
      )
      .force("charge", d3.forceManyBody().strength(-1))
      .force("center", d3.forceCenter(size.width / 2, size.height / 2))
      .force(
        "collide",
        d3.forceCollide().radius(function (d: any) {
          return d.value * 30;
        })
      );

    const svg = d3
      .select(svgRef.current)
      .attr("height", size.height)
      .attr("width", size.width)
      .call(zoom);

    const gHolder = svg.select("g");

    const loc: any = window.location;

    svg
      .append("defs")
      .selectAll("marker")
      .data(["D"])
      .join("marker")
      .attr("id", (d: any) => `arrow-${d}`)
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 15)
      .attr("refY", -0.5)
      .attr("markerWidth", 12)
      .attr("markerHeight", 12)
      .attr("orient", "auto")
      .append("path")
      .attr("fill", "#4988f5")
      .attr("d", "M0,-5L10,0L0,5");

    const link = gHolder
      .append("g")
      .attr("fill", "none")
      .attr("stroke-width", 1.5)
      .selectAll("path")
      .data(links)
      .join("path")
      .attr("stroke", "#4988f5")
      .attr("marker-end", (d: any) => `url(${new URL(`#arrow-D`, loc)})`);

    const node = gHolder
      .append("g")
      .selectAll("g")
      .data(nodes)
      .enter()
      .append("g")
      .each(function (d) {
        d3.select(this)
          .append("circle")
          .attr("r", 1)
          .attr("fill", fillCircle(d.group));
        d3.select(this)
          .append("text")
          .text(d.name + ", " + d.year)
          .attr("dy", 6)
          .style("text-anchor", "middle")
          .attr("class", "node-label")
          .on("click", handleClickItem);
      })
      .call(drag(simulation));

    simulation.on("tick", function () {
      link.attr("d", linkArc);

      link
        .attr("x1", function (d: any) {
          return d.source.x;
        })
        .attr("y1", function (d: any) {
          return d.source.y;
        })
        .attr("x2", function (d: any) {
          return d.target.x;
        })
        .attr("y2", function (d: any) {
          return d.target.y;
        });
      node.attr("transform", function (d: any) {
        return "translate(" + d.x + "," + d.y + ")";
      });
    });
  };

  const handleWindowSize = () => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const handleInputSearch = (e: any) => {
    setSearchValue(e.target.value);

    const nameArray = NETWORK_DATA.nodes
      .filter((el) => {
        return el.name.includes(e.target.value);
      })
      .map((search) => {
        return `${search.name}, ${search.year}`;
      });

    setSearchRecommend(nameArray);
  };

  const handleClickRecommend = (name: string) => {
    console.log(name);
    setIsModalOpen(true);

    setModalDataByName(name);
  };

  const handleClickDebugMode = () => {
    setIsDebugModalOpen(true);
  };

  const handleClickFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  useEffect(() => {
    drawChart();
    window.addEventListener("resize", handleWindowSize);

    return () => {
      window.removeEventListener("resize", handleWindowSize);
    };
  }, []);

  return (
    <div>
      <svg width={size.width} height={size.height} ref={svgRef}>
        <g>
          <g className="link"></g>
          <g className="node"></g>
        </g>
      </svg>

      <Modal
        isOpen={isDebugModalOpen}
        onClose={() => setIsDebugModalOpen(false)}
      >
        <h2>디버그 모드</h2>
        <EditNetworkJson></EditNetworkJson>
      </Modal>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>{modalData.title}</h2>
        <p>
          {modalData.data.year}년 출생 {modalData.data.group}
        </p>

        <hr />

        {modalData.links.map((item) => (
          <>
            <p
              css={css({
                display: "block",
                alignItems: "center",
                fontSize: "1rem",
                paddingTop: "1rem",
              })}
            >
              {" "}
              <span
                css={css({
                  fontSize: "1rem !important",
                  position: "relative",
                  top: "2px",
                })}
                className="material-symbols-outlined"
              >
                arrow_right_alt
              </span>{" "}
              <b>{item.targetName}</b>, {item.relation}
            </p>

            <p> {item.anecdote}</p>
          </>
        ))}
      </Modal>

      <Modal isOpen={isOpenCredit} onClose={() => setIsOpenCredit(false)}>
        <div
          css={css({
            marginBottom: "1rem",
          })}
        >
          <h2 css={css({ margin: "0" })}>세기의 지성인 v0.1</h2>
          <p>
            역사속 지성인들이 서로 어떻게 연결되어 있는지 그 발자취를
            따라가봅니다.
          </p>
        </div>
        <div
          css={css({
            marginBottom: "1.4rem",
          })}
        >
          <h3 css={css({ margin: "0" })}>개발자</h3>
          <p>
            <a
              href="https://hhj.devent.kr/"
              target="_blank"
              rel="noopener noreferrer"
              css={css({
                color: "#2e4cc7",
                textDecoration: "none",
                transition: "0.1s",
                ":hover": {
                  color: "#1f389c",
                },
              })}
            >
              소프트웨어 개발자 허형준
            </a>{" "}
            입니다. 1인 스타트업 데벤트를 설립하고 현재까지 도전을 지속하고
            있습니다.
          </p>
        </div>
        <div
          css={css({
            marginBottom: "0rem",
          })}
        >
          <h3 css={css({ margin: "0" })}>저작권 및 면책조항</h3>
          <p>
            해당 사이트의 저작권 및 권리는 개발자와 자료 가여자들에게 있습니다.
            무단으로 데이터를 추출해 영리목적으로 사용하실 수 없으며 2차 창작에
            이용할 수 없습니다. 개발자와 자료 기여자는 자료의 정확성을 보증할 수
            없으며 해당 사이트에 기입되어 있는 모든 정보에 대한 이용 책임은
            당사자에게 있습니다.
          </p>
        </div>

        <div
          css={css({
            marginBottom: "0rem",
          })}
        >
          <h3 css={css({ margin: "0" })}>설정</h3>

          <p css={css({ marginBottom: "0" })}>
            <Toggle checked={!!document.fullscreenElement} onClick={handleClickFullScreen}>
              전체 화면
            </Toggle>
            <DarkmodeToggle></DarkmodeToggle>
            <Toggle checked={isDebug} onClick={() => setIsDebug(!isDebug)}>
              디버그 모드
            </Toggle>
          </p>
        </div>
      </Modal>

      <div
        css={css({
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
          position: "absolute",
          top: "0",
          left: "0",
          padding: "1rem",
        })}
      >
        <SearchInput
          onClickRecommend={handleClickRecommend}
          onChange={handleInputSearch}
          value={searchValue}
          placeholder={"검색어..."}
          recommend={searchRecommend}
        ></SearchInput>
      </div>

      <div
        css={css({
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          bottom: "0",
          left: "0",
          padding: "0.1rem",
        })}
      >
        <p css={css({ fontSize: "0.6rem", margin: "0" })}>© 허형준 2024</p>
      </div>

      <div
        css={css({
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
          position: "absolute",
          top: "0",
          right: "0",
          padding: "1rem",
        })}
      >
        <ButtonSquare onClick={handleClickCredit}>
          <span
            css={css({
              fontSize: "0.4rem",
              color: "#A4A6B7",
            })}
            className="material-symbols-outlined"
          >
            info
          </span>
        </ButtonSquare>

        {isDebug && (
          <ButtonSquare onClick={handleClickDebugMode}>
            <span
              css={css({
                fontSize: "0.4rem",
                color: "#A4A6B7",
              })}
              className="material-symbols-outlined"
            >
              developer_mode
            </span>
          </ButtonSquare>
        )}
      </div>

      <div
        css={css({
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "1rem",
        })}
      >
        <ButtonSquare onClick={handleZoom}>
          <span
            css={css({
              fontSize: "0.4rem",
              color: "#A4A6B7",
            })}
            className="material-symbols-outlined"
          >
            add
          </span>
        </ButtonSquare>
        <ButtonSquare onClick={handleZoomOut}>
          {" "}
          <span
            css={css({
              fontSize: "0.4rem",
              color: "#A4A6B7",
            })}
            className="material-symbols-outlined"
          >
            remove
          </span>
        </ButtonSquare>
      </div>
    </div>
  );
}

export { Network };
