/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";
import { useColorMode } from "deventds2";

type ModalType = {
  children?: any;
  onClose?: any;
  isOpen?: boolean;
};

function SearchInput({
  onChange,
  value,
  placeholder,
  recommend = [""],
  onClickRecommend,
}: any) {
  const [colorMode, setColorMode] = useColorMode();

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = (e: any) => {
    if (e.target.id != "modal") {
      return false;
    }
    setIsOpen(false);
  };
  const handleClickRecommendItem = (name: string) => {
    onClickRecommend(name);
  };
  return (
    <div>
      <input
        onClick={() => setIsOpen(true)}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        css={css({
          position: "fixed",
          backgroundColor: colorMode == "light" ? "#EEEFF5" : "#3d3e45",
          color: colorMode == "light" ? "#000000" : "#ffffff",

          borderRadius: "0.4rem",
          border: "none",
          padding: "0.5rem",
          outline: "none",
          zIndex: 2500,
        })}
      ></input>

      <div
        onClick={handleClose}
        id="modal"
        css={css({
          display: isOpen ? "flex" : "none",
          width: "100vw",
          height: "100vh",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: 2000,
        })}
      >
        <div
          css={css({
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
          })}
        >
          <div
            css={css({
              display: "flex",
              flexDirection: "column",
              paddingTop: "3rem",
              margin: "1rem",
              gap: "0.4rem",
              scrollbarWidth: "none",
            })}
          >
            {recommend.map((el: any) => (
              <div
                onClick={() => handleClickRecommendItem(el)}
                css={css({
                  backgroundColor: colorMode == "light" ? "#EEEFF5" : "#3d3e45",
                  padding: "1rem",
                  borderRadius: "0.4rem",
                  cursor: "pointer",
                  transition: "0.1s",
                  ":hover": {
                    backgroundColor: colorMode == "light" ? "#e1e2e8" : "#2c3036",
                  },
                })}
              >
                {el}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export { SearchInput };
