/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";
import { useColorMode } from "deventds2";

type ModalType = {
  children?: any;
  onClose?: any;
  isOpen?: boolean;
};

function ButtonSquare({ onClick, children }: any) {
  const [colorMode, setColorMode] = useColorMode();

  return (
    <button
      onClick={onClick}
      css={css({
        outline: "0",
        width: "2rem",
        height: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "0",
        backgroundColor: colorMode == "light" ? "#EEEFF5" : "#3d3e45",
        borderRadius: "0.4rem",
        cursor: "pointer",
        transition: "0.1s",
        ":hover": {
          backgroundColor: colorMode == "light" ? "#e1e2e8" : "#2c3036",
        },
      })}
    >
      {children}
    </button>
  );
}

export { ButtonSquare };
