/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";

function FullScreen({ children }: any) {
  return (
    <div
      css={css({
        width: "100%",
        height: "100%",
        overflow: "hidden",
      })}
    >
      {children}
    </div>
  );
}

export { FullScreen };
