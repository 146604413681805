export default {
  nodes: [
    {
      name: "니콜라우스 코페르니쿠스",
      year: 1473,
      id: "1",
      value: 3,
      group: "과학자",
    },
    {
      name: "갈릴레오 갈릴레이",
      year: 1564,
      id: "2",
      value: 3,
      group: "과학자",
    },
    {
      name: "아이작 뉴턴",
      year: 1643,
      id: "3",
      value: 3,
      group: "과학자",
    },
    {
      name: "르네 데카르트",
      year: 1596,
      id: "4",
      value: 3,
      group: "과학자",
    },
    {
      name: "요하네스 케플러",
      year: 1571,
      id: "5",
      value: 3,
      group: "과학자",
    },
    {
      name: "라이프니츠",
      year: 1646,
      id: "6",
      value: 3,
      group: "과학자",
    },
    {
      name: "알베르트 아인슈타인",
      year: 1879,
      id: "7",
      value: 3,
      group: "과학자",
    },
    {
      name: "막스 플랑크",
      year: 1858,
      id: "8",
      value: 3,
      group: "과학자",
    },
    {
      name: "닐스 보어",
      year: 1885,
      id: "9",
      value: 3,
      group: "과학자",
    },
    {
      name: "에르빈 슈뢰딩거",
      year: 1887,
      id: "10",
      value: 3,
      group: "과학자",
    },

    {
      name: "튀코 브라헤",
      year: 1546,
      id: "11",
      value: 3,
      group: "천문학자",
    },

    {
      name: "볼프강 에른스트 파울리",
      year: 1900,
      id: "12",
      value: 3,
      group: "물리학자",
    },

    {
      name: "베르너 하이젠베르크",
      year: 1901,
      id: "13",
      value: 3,
      group: "물리학자",
    },
    {
      name: "막스 보른",
      year: 1858,
      id: "14",
      value: 3,
      group: "과학자",
    },
    {
      name: "아르투어 쇼펜하우어",
      year: 1788,
      id: "15",
      value: 3,
      group: "과학자",
    },

    {
      name: "루트비히 비트겐슈타인",
      year: 1889,
      id: "16",
      value: 3,
      group: "철학자",
    },

    {
      name: "프란츠 카프카",
      year: 1883,
      id: "17",
      value: 3,
      group: "작가",
    },
    {
      name: "오노레 드 발자크",
      year: 1799,
      id: "18",
      value: 3,
      group: "작가",
    },
    {
      name: "레프 톨스토이",
      year: 1828,
      id: "19",
      value: 3,
      group: "작가",
    },
    {
      name: "표도르 도스토옙스키",
      year: 1821,
      id: "20",
      value: 3,
      group: "작가",
    },
    {
      name: "무라카미 하루키",
      year: 1949,
      id: "21",
      value: 3,
      group: "작가",
    },

    {
      name: "프리드리히 니체",
      year: 1844,
      id: "22",
      value: 3,
      group: "철학자",
    },
    {
      name: "카를 융",
      year: 1875,
      id: "23",
      value: 3,
      group: "철학자",
    },
    {
      name: "살바도르 달리",
      year: 1904,
      id: "24",
      value: 3,
      group: "예술가",
    },
    {
      name: "미셸 푸코",
      year: 1926,
      id: "25",
      value: 3,
      group: "철학자",
    },
    {
      name: "알베르 카뮈",
      year: 1913,
      id: "26",
      value: 3,
      group: "철학자",
    },

    {
      name: "로베르트 슈만",
      year: 1810,
      id: "27",
      value: 3,
      group: "음악가",
    },
    {
      name: "요하네스 브람스",
      year: 1833,
      id: "28",
      value: 3,
      group: "음악가",
    },
    {
      name: "안토닌 드보르자크",
      year: 1841,
      id: "29",
      value: 3,
      group: "음악가",
    },
    {
      name: "아르놀트 쇤베르크",
      year: 1874,
      id: "30",
      value: 3,
      group: "작곡가",
    },
    {
      name: "리하르트 바그너",
      year: 1813,
      id: "31",
      value: 3,
      group: "음악가",
    },
    {
      name: "구스타프 말러",
      year: 1860,
      id: "32",
      value: 3,
      group: "작곡가",
    },
    {
      name: "헨리 데이비드 소로",
      year: 1817,
      id: "33",
      value: 3,
      group: "작가",
    },
    {
      name: "찰스 로버트 다윈",
      year: 1817,
      id: "34",
      value: 3,
      group: "과학자",
    },
    {
      name: "랠프 월도 에머슨",
      year: 1803,
      id: "35",
      value: 3,
      group: "작가",
    },
    {
      name: "루이자 메이 올컷",
      year: 1803,
      id: "36",
      value: 3,
      group: "작가",
    },
    {
      name: "지그문트 프로이트",
      year: 1865,
      id: "37",
      value: 3,
      group: "심리학자",
    },
    {
      name: "파블로 피카소",
      year: 1881,
      id: "38",
      value: 3,
      group: "미술가",
    },
    {
      name: "장앙리 파브르",
      year: 1823,
      id: "39",
      value: 3,
      group: "교수",
    },
    {
      name: "리처드 오언",
      year: 1804,
      id: "40",
      value: 3,
      group: "생물학자",
    },
    {
      name: "찰스 라이엘",
      year: 1797,
      id: "41",
      value: 3,
      group: "지질학자",
    },
    {
      name: "존 스튜어트 밀",
      year: 1806,
      id: "42",
      value: 3,
      group: "사회학자",
    },
    {
      name: "애덤 스미스",
      year: 1723,
      id: "43",
      value: 3,
      group: "정치경제학자",
    },
    {
      name: "아리스토텔레스",
      year: -384,
      id: "44",
      value: 3,
      group: "철학자",
    },
    {
      name: "버트런드 러셀",
      year: 1872,
      id: "45",
      value: 3,
      group: "철학자",
    },
  ],
  links: [
    {
      source: "1",
      target: "2",
      value: 2,
      relation: "갈릴레이 지동설 입증",
      anecdote: "",
    },
    {
      source: "44",
      target: "26",
      value: 2,
      relation: "영향",
      anecdote: "카뮈는 아리스토텔레스의 명제를 인용한다",
    },
    {
      source: "22",
      target: "31",
      value: 2,
      relation: "교류",
      anecdote: "",
    },
    {
      source: "44",
      target: "42",
      value: 2,
      relation: "영향",
      anecdote: "",
    },
    {
      source: "43",
      target: "42",
      value: 2,
      relation: "영향",
      anecdote: "",
    },
    {
      source: "42",
      target: "39",
      value: 2,
      relation: "교류",
      anecdote: "",
    },
    {
      source: "39",
      target: "42",
      value: 2,
      relation: "교류",
      anecdote: "",
    },
    {
      source: "41",
      target: "34",
      value: 2,
      relation: "진화론 지지",
      anecdote: "",
    },
    {
      source: "40",
      target: "34",
      value: 2,
      relation: "진화론 반박",
      anecdote: "",
    },
    {
      source: "39",
      target: "34",
      value: 2,
      relation: "친구",
      anecdote: "",
    },
    {
      source: "34",
      target: "39",
      value: 2,
      relation: "친구",
      anecdote: "",
    },
    {
      source: "38",
      target: "24",
      value: 2,
      relation: "살바도르 달리가 존경",
      anecdote: "",
    },
    {
      source: "37",
      target: "24",
      value: 2,
      relation: "살바도르 달리의 팬",
      anecdote: "",
    },
    {
      source: "33",
      target: "36",
      value: 2,
      relation: "소로는 올컷의 스승",
      anecdote: "",
    },
    {
      source: "35",
      target: "33",
      value: 2,
      relation: "에머슨은 소로의 스승",
      anecdote: "",
    },
    {
      source: "35",
      target: "33",
      value: 2,
      relation: "에머슨은 소로의 스승",
      anecdote: "",
    },
    {
      source: "34",
      target: "33",
      value: 2,
      relation: "친구",
      anecdote: "",
    },
    {
      source: "33",
      target: "34",
      value: 2,
      relation: "친구",
      anecdote: "",
    },
    {
      source: "22",
      target: "32",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "31",
      target: "32",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "31",
      target: "28",
      value: 2,
      relation: "경쟁",
      anecdote: "",
    },
    {
      source: "28",
      target: "31",
      value: 2,
      relation: "경쟁",
      anecdote: "",
    },
    {
      source: "22",
      target: "30",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "28",
      target: "30",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "28",
      target: "29",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "16",
      target: "28",
      value: 2,
      relation: "비트겐슈타인의 집에서 공연",
      anecdote: "",
    },
    {
      source: "45",
      target: "16",
      value: 2,
      relation: "비트겐슈타인의 스승",
      anecdote: "",
    },
    {
      source: "42",
      target: "45",
      value: 2,
      relation: "러셀의 대부",
      anecdote: "",
    },
    {
      source: "27",
      target: "28",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "26",
      target: "25",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "25",
      target: "26",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "22",
      target: "25",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "22",
      target: "24",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "22",
      target: "23",
      value: 2,
      relation: "논문분석",
      anecdote: "",
    },
    {
      source: "15",
      target: "22",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "17",
      target: "21",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "15",
      target: "20",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "15",
      target: "19",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "15",
      target: "18",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "15",
      target: "17",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "15",
      target: "10",
      value: 2,
      relation: "사상 수용",
      anecdote: "",
    },
    {
      source: "16",
      target: "10",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "14",
      target: "12",
      value: 2,
      relation: "조교",
      anecdote: "",
    },
    {
      source: "13",
      target: "10",
      value: 2,
      relation: "연구",
      anecdote: "",
    },
    {
      source: "13",
      target: "12",
      value: 2,
      relation: "친구",
      anecdote: "",
    },
    {
      source: "2",
      target: "3",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "3",
      target: "7",
      value: 2,
      relation: "현대 물리 발전 기여",
      anecdote: "",
    },
    {
      source: "7",
      target: "10",
      value: 2,
      relation: "양자역학 연구 및 대립",
      anecdote: "",
    },
    {
      source: "9",
      target: "12",
      value: 2,
      relation: "조교수",
      anecdote: "",
    },
    {
      source: "11",
      target: "5",
      value: 2,
      relation: "관측자료 제공",
      anecdote: "",
    },
    {
      source: "3",
      target: "6",
      value: 2,
      relation: "미적분 대립",
      anecdote: "",
    },
    {
      source: "5",
      target: "3",
      value: 2,
      relation: "케플러 이론 고도화",
      anecdote: "",
    },
    {
      source: "5",
      target: "2",
      value: 2,
      relation: "지지",
      anecdote: "",
    },
    {
      source: "2",
      target: "4",
      value: 2,
      relation: "",
      anecdote: "",
    },
    {
      source: "4",
      target: "3",
      value: 2,
      relation: "해석기하학 제공",
      anecdote: "",
    },
  ],
};
